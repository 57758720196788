@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;700&display=swap');

body, html{
    height: 100%;
    margin: 0 auto;
}

*{
    box-sizing: border-box;
}

.navbar{
    font-family: 'Oswald', sans-serif;
    width: 100%;
    height: 7em;
    display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	cursor: pointer;
    color: rgb(255, 255, 255);
    /* background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(0, 255, 255, 0.274)); */
}

.navbar a{
    text-decoration: none;
}

.logodiv{
    width: 70%;
}

.logodiv img{
    position: absolute;
    top: 10px;
    left: 125px;
}


.logodiv h3{
    color: black;
    position: absolute;
    top: 10px;
    left: 215px;
}

.logodiv p{
    color: black;
    position: absolute;
    top: 40px;
    left: 215px;
}

.logo{
    width: 80px; 
    height: 80px;
    margin-right: 20px;
    margin-top: 5px;
}

.navbarlist{
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.navbarlist li a{
    display: block;
    padding: 14px;
    justify-content: center;
    color: rgb(0, 0, 0);
    font-size: 18px;
    opacity: 0.8;
    transition: all 250ms;
}

.navbarlist li a:hover {
    /* background-color: rgba(167, 149, 248, 0.438); */
    font-size: 24px;
    opacity: 1;
}

.navbarlist li {
    float: left;
}

.footer{
    background-color: #eff1f2;
    margin: 0 auto;
    padding-top: 5px;
    padding-bottom: 25px;
    text-align: center;
}

.footer_text{
    padding: 10px;
}