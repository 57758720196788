@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Hammersmith+One&display=swap");

body,
html {
  height: 100%;
  margin: 0 auto;
}

* {
  box-sizing: border-box;
}

.contact {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.info {
  width: 50%;
  padding-top: 15px;
  text-align: center;
}

.message {
  width: 50%;
  /* height: 1000px; */
}

.msgformclass {
  width: 25%;
  padding: 0 14px 14px 14px;
  border: none;
  position: absolute;
  transform: translate(300px, -50px);
}

.contact input[type="text"],
input[type="email"],
input[type="number"] {
  width: 100%;
  padding: 12px;
  margin: 5px 0 22px 0;
  display: inline-block;
  border: none;
  border-bottom: 1px solid rgb(141, 141, 141);
  transition: all 250ms;
}

.contact input[type="text"]:focus,
input[type="email"]:focus,
input[type="number"]:focus {
  border-bottom: 1px solid rgb(0, 0, 0);
  outline: none;
}

.socialMedia {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.socialLink {
  text-decoration: none;
  color: #333;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
}

.socialIcon {
  margin-right: 0.5rem;
  font-size: 1.5rem;
}

.socialLink:hover {
  color: #0077b5; 
}

.socialLink[href*="instagram.com"]:hover {
  color: #E1306C; 
}

.message_btn {
  background-color: black;
  color: white;
  cursor: pointer;
  font-size: 16px;
  padding: 18px 20px;
  margin: 8px 0;
  border: none;
  width: 100%;
  opacity: 0.9;
}
.message_btn:hover {
  opacity: 1;
}

.timings {
  font-family: "Times New Roman", Times, serif;
  background-color: #eff1f2;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 10px;
}

.timing_quote {
  padding: 25px;
}

.about_us {
  font-family: "Times New Roman", Times, serif;
  background-color: #eff1f2;
  text-align: center;
  padding: 50px;
  margin: 0 auto;
}

.about_text {
  max-width: 50%;
  margin: auto;
  display: flex;
  justify-content: center;
  line-height: 2em;
}

.blank_div {
  width: 100%;
  height: 450px;
}

.water_home,
.water_about {
  width: 100%;
}

.locations {
  max-width: 100%;
  height: 550px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  align-content: normal;
}

.locations a {
  color: #000;
}

.office_div,
.factory_div {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.office_div img,
.factory_div img {
  max-width: 96%;
  margin: 10px 0 15px 0;
  transition: all 250ms;
  /* margin-top: 10px;
  margin-bottom: 10px; */
}

.office_div:hover img,
.factory_div:hover img {
  max-width: 92%;
}

.office_div h2,
.factory_div h2 {
  position: absolute;
  font-family: "Hammersmith One", sans-serif;
  font-size: 48px;
  letter-spacing: 5px;
  transition: all 250ms;
  /* color: #fff; */
}

.office_div:hover h2,
.factory_div:hover h2 {
  font-size: 56px;
}

.about_div {
  /* text-align: center; */
  padding: 50px;
  margin: 0 auto;
}

.about_div h2 {
  padding: 20px;
}

.about_top_div {
  text-align: center;
}

.about_top_div h1,
h2 {
  font-family: "Times New Roman", Times, serif;
}

.about_text_div {
  max-width: 80%;
  margin: auto;
  /* display: flex; */
  /* justify-content: center; */
  line-height: 2em;
}

.about_text_div p,
ul li {
  font-family: "Poppins", sans-serif;
}

.itemListDiv {
  display: flex;
  /* height: 75%; */
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}

.productName {
  margin: 25px 0 50px 100px;
}

.productName p {
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.5em;
  padding-bottom: 15px;
  position: relative;
  /* font-size: 30px;
  padding: 25px; */
}

.productName p:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 5px;
  width: 55px;
  background-color: #111;
}

.productName p:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 2px;
  height: 1px;
  width: 95%;
  max-width: 500px;
  background-color: #333;
}

.product_images_container {
  display: grid;
  margin-top: 25px;
  margin-left: 100px;
  width: 50%;
}

.thumbnail_view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
}

.thumbnail_view img {
  width: 10rem;
  height: 10rem;
  cursor: pointer;
  margin-bottom: 1rem;
}

.main_image_view {
  grid-row: 1 / span 3;
  grid-column: 2 / span 2;
}

.main_image_view img {
  width: 75%;
  height: 90%;
}

.productSpecs {
  position: absolute;
  top: 20%;
  left: 63%;
  margin-right: 75px;
}

.descText {
  text-align: justify;
}

.slider {
  position: relative;
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slideImage {
  max-width: 100%;
  max-height: 100%;
}

.nextArrow,
.prevArrow {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: black;
  border: none;
  background-color: transparent;
  font-weight: bold;
  font-size: 24px;
  transition: 0.3s ease;
  user-select: none;
}

.nextArrow {
  right: 0;
}

.prevArrow {
  left: 0;
}

.nextArrow:hover,
.prevArrow:hover {
  font-size: 30px;
}

/* .brandHeading {
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  margin-bottom: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
} */

/* .headingContainer {
  text-align: center;
  margin-bottom: 2rem;
}

.brandHeading {
  font-size: 2.5rem;
  font-weight: 700;
  color: #333;
  border-bottom: 2px solid #e0e0e0;
  display: inline-block;
  padding-bottom: 0.5rem;
} */

.headingContainer {
  text-align: center;
  margin-bottom: 3rem;
}

.brandHeading {
  font-size: 3rem;
  font-weight: 700;
  color: #004085;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 0.5rem;
}

.brandHeading::after {
  content: "";
  width: 50px;
  height: 4px;
  background: #004085;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.brandSlider {
  /* margin-top: 2rem; */
  margin-bottom: 2rem;
}

.brandSlideShow {
  display: flex;
  justify-content: center;
  align-items: center;
}

.brandImg {
  width: 100%;
  height: 100%;
  object-fit: contain;
  aspect-ratio: 3 / 2;
  max-width: 14rem;
  max-height: 6rem;
}